import { Choice, getPropertyByString } from "../../../lib/utils";
import CoreBaseBackedObject from "../../coreObjects/lib/coreBaseBackedObject";
import { FieldType } from "./field-type";
import {
  ChoiceFieldConcrete,
  FlatPropertyFields,
  FlowSystemChoiceField,
} from "./property-field";
import { DrawableEntity } from "./simple-entities";
import { getAvailableHeatLoadComponentMaterial } from "./utils";

export function isFieldEmpty(
  field: FlatPropertyFields,
  obj: CoreBaseBackedObject,
): boolean {
  const value = getPropertyByString(obj.entity, field.property);
  return value === null || value === "";
}

export function isInvalidByRule(
  field: FlatPropertyFields,
  filled: DrawableEntity,
): boolean {
  const value = getPropertyByString(filled, field.property);
  const failedRule = field.validationRules!.find(
    (rule) => !rule.predicate(value),
  );
  return !!failedRule;
}

export function isInvalidChoice(
  field: ChoiceFieldConcrete,
  obj: CoreBaseBackedObject,
  filled: DrawableEntity,
): boolean {
  const val = getPropertyByString(filled, field.property);
  const choices = allChoices(obj, field) || [];
  const validChoices = choices.filter((c) => !c.disabled);

  if (field.params.allowNonItemValue) {
    // I guess we could validate `val && val !== ""` but that feels like a different kind
    // of validation than "invalidChoice" validation strictly speaking
    return false;
  } else {
    return (
      val &&
      val !== "" &&
      validChoices &&
      validChoices.length > 0 &&
      !validChoices.find((c) => c.key == val)
    );
  }
}

export function allChoices(
  obj: CoreBaseBackedObject,
  field: ChoiceFieldConcrete,
): Choice[] {
  if (field.type === FieldType.HeatLossMaterialChoice) {
    return getAvailableHeatLoadComponentMaterial(
      obj.context,
      field.params.role,
    );
  }
  return field.params.choices;
}

export function isInvalidFlowSystemChoice(
  field: FlowSystemChoiceField,
  filled: DrawableEntity,
): boolean {
  const val = getPropertyByString(filled, field.property);
  const disabledSystems = field.params.disabledSystems ?? [];

  return disabledSystems.some((c) => c === val);
}
